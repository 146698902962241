
import { Actions } from "@/store/enums/ProductTransportCostEnums.ts";
import { Actions as LocationActions } from "@/store/enums/LocationEnums.ts";
import Table from '@/components/custom/Table.vue';
import DeleteRecord from '@/components/custom/DeleteRecord.vue';
import AddTransportCost from "@/views/products/composites/edit/transport_costs/AddTransportCost.vue";
import EditTransportCost from "@/views/products/composites/edit/transport_costs/EditTransportCost.vue";
import { useStore } from 'vuex'
import { computed, ref, reactive, onMounted } from 'vue'
import numeral from 'numeral'
export default {
  name : 'TransportCosts',
  props : ["id"],
  components : {
    Table,
    DeleteRecord,
    AddTransportCost,
    EditTransportCost
  },
  setup(props){

    const store = useStore();

    const actions = computed(() => {
      return Actions;
    })

    onMounted(() => {
      store.dispatch(LocationActions.GET_LOCATIONS);
    })

    const columns = [
      {name : 'location', title : 'Location', sortable : true, sort : 'asc', searchable : true},
      {name : 'cost', title : 'Cost per Carton', sortable : true, sort : null, searchable : true},
      {name : 'actions', title : 'Edit / Delete',sortable : false, sort : null, searchable : false}
    ];

    return {
      actions,
      props,
      columns,
      store,
      numeral
    }
  }
}
