<template>
  <div>
    <div class="card mb-5 mb-xl-10" id="kt_profile_details_view">
      <!--begin::Card header-->
      <div class="card-header cursor-pointer">
        <!--begin::Card title-->
        <div class="card-title m-0">
          <h3 class="fw-bolder m-0">Edit Composite</h3>
        </div>
        <!--end::Card title-->
      </div>

      <!--begin::Card body-->
      <div class="card-body p-9">

        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <button class="nav-link active" id="details-tab" data-bs-toggle="tab" data-bs-target="#details" type="button" role="tab" aria-controls="details" aria-selected="true">Details</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="bom-tab" data-bs-toggle="tab" data-bs-target="#bom" type="button" role="tab" aria-controls="bom" aria-selected="false">BOM</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="transport-costs-tab" data-bs-toggle="tab" data-bs-target="#transport-costs" type="button" role="tab" aria-controls="transport-costs" aria-selected="false">Transport Costs</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="activity-tab" data-bs-toggle="tab" data-bs-target="#activity" type="button" role="tab" aria-controls="activity" aria-selected="false">Activity</button>
          </li>
          <!-- <li class="nav-item" role="presentation">
            <button class="nav-link" id="agreed-prices-tab" data-bs-toggle="tab" data-bs-target="#agreed-prices" type="button" role="tab" aria-controls="agreed-prices" aria-selected="false">Agreed Prices</button>
          </li> -->
        </ul>
        <div class="tab-content" id="myTabContent">
          <div class="tab-pane fade show active" id="details" role="tabpanel" aria-labelledby="details-tab">
            <CompositeDetails :id="route.params.id" />
          </div>
          <div class="tab-pane fade" id="bom" role="tabpanel" aria-labelledby="bom-tab">
            <CompositeBOM :id="route.params.id" />
          </div>
          <div class="tab-pane fade" id="transport-costs" role="tabpanel" aria-labelledby="transport-costs-tab">
            <TransportCosts :id="route.params.id" />
          </div>
          <div class="tab-pane fade" id="activity" role="tabpanel" aria-labelledby="activity-tab">
            <CompositeActivities :id="route.params.id" />
          </div>
          <!-- <div class="tab-pane fade" id="agreed-prices" role="tabpanel" aria-labelledby="agreed-prices-tab">...</div> -->
        </div>

      </div>

    </div>
  </div>
</template>
<script lang="ts">
import { onMounted } from 'vue';
import CompositeDetails from '@/views/products/composites/edit/CompositeDetails.vue'
import CompositeBOM from '@/views/products/composites/edit/CompositeBOM.vue'
import TransportCosts from '@/views/products/composites/edit/TransportCosts.vue'
import CompositeActivities from '@/views/products/composites/edit/CompositeActivities.vue'
import { useRoute } from 'vue-router';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
export default {
  name: 'CompositeEdit',
  components : {
    CompositeDetails,
    CompositeBOM,
    TransportCosts,
    CompositeActivities
  },
  setup(){
    const route = useRoute();

    onMounted(() => {
      setCurrentPageBreadcrumbs("Products",[
        {title : "Products",to : "/products/list"},
        {title : "Edit Composite",to : "/products/composite/edit/"+route.params.id}
      ]);
    });

    return {
      route
    }
  }
}
</script>
