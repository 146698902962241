<template>
  <div class="pt-10">

    <div class="row mb-3">
      <div class="col-12 mb-5" v-show="success">
        <div class="row mb-0">
          <div class="col-12">
            <div class="alert alert-success" role="alert">
              Successfully changed composite!
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-4 mb-3 mb-lg-0">
        <div class="mb-3 pb-3 border-bottom">
          <h3 class="fw-400">Product Details</h3>
        </div>
        <div class="form-group mb-3">
          <label class="col-form-label required fw-bold fs-6">Product Type</label>
          <input type="text" class="form-control" :class="{'is-invalid' : errors.value && errors.value.product_type_id}" value="COMPOSITE" :readonly="true">
          <span class="text-danger" v-if="errors.value && errors.value.product_type_id">{{errors.value && errors.value.product_type_id[0]}}</span>
        </div>
        <div class="form-group mb-3">
          <label class="col-form-label required fw-bold fs-6">Product Code</label>
          <input type="text" class="form-control" :class="{'is-invalid' : errors.value && errors.value.code}" v-model="store.getters.currentComposite.code" placeholder="Enter product code">
          <span class="text-danger" v-if="errors.value && errors.value.code">{{errors.value && errors.value.code[0]}}</span>
        </div>
        <div class="form-group mb-3">
          <label class="col-form-label required fw-bold fs-6">Supplier Code</label>
          <input type="text" class="form-control" :class="{'is-invalid' : errors.value && errors.value.supplierCode}" v-model="store.getters.currentComposite.supplierCode" placeholder="Enter supplier code">
          <span class="text-danger" v-if="errors.value && errors.value.supplierCode">{{errors.value && errors.value.supplierCode[0]}}</span>
        </div>
        <div class="form-group mb-3">
          <label class="col-form-label fw-bold fs-6">UDI</label>
          <input type="text" class="form-control" :class="{'is-invalid' : errors.value && errors.value.udi}" v-model="store.getters.currentComposite.udi" placeholder="Enter UDI">
          <span class="text-danger" v-if="errors.value && errors.value.udi">{{errors.value && errors.value.udi[0]}}</span>
        </div>
        <div class="form-group mb-3">
          <label class="col-form-label required fw-bold fs-6">Name</label>
          <input type="text" class="form-control" :class="{'is-invalid' : errors.value && errors.value.name}" v-model="store.getters.currentComposite.name" placeholder="Enter name">
          <span class="text-danger" v-if="errors.value && errors.value.name">{{errors.value && errors.value.name[0]}}</span>
        </div>
        <div class="form-group mb-3">
          <label class="col-form-label fw-bold fs-6">Description</label>
          <textarea class="form-control" :class="{'is-invalid' : errors.value && errors.value.description}" v-model="store.getters.currentComposite.description" placeholder="Enter description"></textarea>
          <span class="text-danger" v-if="errors.value && errors.value.description">{{errors.value && errors.value.description[0]}}</span>
        </div>
        <div class="form-group mb-3">
          <label class="col-form-label required fw-bold fs-6">Category</label>
          <select class="form-select" :class="{'is-invalid' : errors.value && errors.value.product_category_id}" v-model="store.getters.currentComposite.product_category_id">
            <option selected disabled>Select Category</option>
            <option :value="item.id" v-for="(item,key) in store.getters.myProductCategories" :key="key">{{item.name}}</option>
          </select>
          <span class="text-danger" v-if="errors.value && errors.value.product_category_id">{{errors.value && errors.value.product_category_id[0]}}</span>
        </div>

        <div class="form-group mb-3">
          <label class="col-form-label fw-bold fs-6">Device Classification</label>
          <select class="form-select" :class="{'is-invalid' : errors.value && errors.value.device_classification_id}" v-model="store.getters.currentComposite.device_classification_id">
            <option selected disabled>Select Please</option>
            <option :value="item.id" v-for="(item,key) in store.getters.myClassificationCategoies" :key="key">{{item.name}}</option>
          </select>
          <span class="text-danger" v-if="errors.value && errors.value.device_classification_id">{{errors.value && errors.value.device_classification_id[0]}}</span>
        </div>

        <div class="form-group mb-3">
          <label class="col-form-label required fw-bold fs-6">Weight(kg)</label>
          <input type="number" class="form-control" :class="{'is-invalid' : errors.value && errors.value.weight}" v-model="store.getters.currentComposite.weight" placeholder="Enter weight">
          <span class="text-danger" v-if="errors.value && errors.value.weight">{{errors.value && errors.value.weight[0]}}</span>
        </div>

        <div class="form-group">
          <label class="col-form-label fw-bold fs-6">Obsolete</label>
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" v-model="store.getters.currentComposite.obsolete">
            <label class="form-check-label" for="flexSwitchCheckChecked"></label>
          </div>
        </div>
      </div>


      <div class="col-12 col-lg-4 mb-3 mb-lg-0">
        <div class="mb-3 pb-3 border-bottom">
          <h3 class="fw-400">Packaging</h3>
        </div>

        <div class="form-group mb-3">
          <label class="col-form-label fw-bold fs-6">Labels Per Pack</label>
          <input type="number" class="form-control" :class="{'is-invalid' : errors.value && errors.value.labelsPerPack}" v-model="store.getters.currentComposite.labelsPerPack" placeholder="Enter labels per pack">
          <span class="text-danger" v-if="errors.value && errors.value.labelsPerPack">{{errors.value && errors.value.labelsPerPack[0]}}</span>
        </div>

        <div class="form-group mb-3">
          <label class="col-form-label fw-bold fs-6">Carton Quantity</label>
          <input type="number" class="form-control" :class="{'is-invalid' : errors.value && errors.value.cartonQty}" v-model="store.getters.currentComposite.cartonQty" placeholder="Enter carton quantity">
          <span class="text-danger" v-if="errors.value && errors.value.cartonQty">{{errors.value && errors.value.cartonQty[0]}}</span>
        </div>

        <div class="form-group mb-3">
          <label class="col-form-label fw-bold fs-6">Labels Per Carton</label>
          <input type="number" class="form-control" :class="{'is-invalid' : errors.value && errors.value.labelsPerCarton}" v-model="store.getters.currentComposite.labelsPerCarton" placeholder="Enter labels per carton">
          <span class="text-danger" v-if="errors.value && errors.value.labelsPerCarton">{{errors.value && errors.value.labelsPerCarton[0]}}</span>
        </div>

        <div class="form-group mb-3">
          <label class="col-form-label fw-bold fs-6">Additional Labels</label>
          <input type="number" class="form-control" :class="{'is-invalid' : errors.value && errors.value.additionalLabels}" v-model="store.getters.currentComposite.additionalLabels" placeholder="Enter additional labels">
          <span class="text-danger" v-if="errors.value && errors.value.additionalLabels">{{errors.value && errors.value.additionalLabels[0]}}</span>
        </div>

      </div>

      <div class="col-12 col-lg-4 mb-3 mb-lg-0">
        <div class="mb-3 pb-3 border-bottom">
          <h3 class="fw-400">Notes</h3>
        </div>

        <div class="form-group mb-3">
          <label class="col-form-label fw-bold fs-6">Note</label>
          <textarea class="form-control" :class="{'is-invalid' : errors.value && errors.value.note}" v-model="store.getters.currentComposite.note" placeholder="Enter note"></textarea>
          <span class="text-danger" v-if="errors.value && errors.value.note">{{errors.value && errors.value.note[0]}}</span>
        </div>


      </div>

    </div>

    <div class="row">
      <div class="col-12">
        <div class="text-end m-0 pt-5 border-top">
          <button
            type="submit"
            ref="submitButton"
            class="btn btn-lg btn-primary fw-bolder mt-2"
            @click="submitForm"
          >
            <span class="indicator-label">
              Save
            </span>

            <span class="indicator-progress">
              Please wait...
              <span
                class="spinner-border spinner-border-sm align-middle ms-2"
              ></span>
            </span>
          </button>
        </div>
      </div>
    </div>

  </div>
</template>
<script lang="ts">
import { reactive, ref, onMounted } from 'vue'
import { useStore } from "vuex";
import { Actions } from "@/store/enums/ProductEnums.ts";
import { Actions as CurrencyActions } from "@/store/enums/CurrencyEnums.ts";
import { useRouter } from "vue-router";
import { Actions as StoreActions } from "@/store/enums/StoreEnums.ts";
export default {
  name : 'CompositeDetails',
  props : ["id"],
  setup(props){

    const store = useStore();
    const router = useRouter();

    const success = ref(false);

    store.dispatch(StoreActions.ADD_BODY_CLASSNAME,'page-loading')

    onMounted(() => {
      store.dispatch(Actions.GET_COMPOSITE ,{id : props.id})
      store.dispatch(Actions.GET_PRODUCT_CATEGORIES);
      store.dispatch(Actions.GET_DEVICE_CLASSIFICATION);
      store.dispatch(CurrencyActions.GET_CURRENCIES).then(() => {

        store.dispatch(StoreActions.REMOVE_BODY_CLASSNAME,'page-loading')

      });
    });


    const submitButton = ref<HTMLElement | null>(null);

    const errors = reactive({
      value : {}
    });


    const submitForm = () => {
      if(submitButton.value){
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      store.dispatch(Actions.UPDATE_PRODUCT,store.getters.currentComposite).then(() => {
        errors.value = [];
        success.value = true;
        window.scrollTo(0,0);
        submitButton.value?.removeAttribute("data-kt-indicator");
        setTimeout(() => {
          router.push('/products/list')
        },1000)

      }).catch((response) => {
        setTimeout(function(){
          const input = document.getElementsByClassName('is-invalid')[0] as HTMLElement
          input.focus();
        },100)

        errors.value = response.data.errors;

        submitButton.value?.removeAttribute("data-kt-indicator");

      })
    }

    return {
      success,
      store,
      submitButton,
      submitForm,
      errors
    }
  }
}
</script>
