<template>
  <div class="pt-10">
    <div class="mb-10">
      <h3 class="fw-400">Product BOM List</h3>
    </div>

    <Table :columns="columns" ref="table" :action="actions.SEARCH_PRODUCT_BOM" :id="Number(props.id)">


      <template v-slot:extra="slotProps">

        <div class="mb-5">

            <AddBOM :id="props.id" @input="slotProps.search()"/>

        </div>

        <div class="mb-10">
          <div class="row mb-3" v-show="success">
            <div class="col-12">
              <div class="alert alert-success" role="alert">
                Successfully added BOM comment!
              </div>
            </div>
          </div>

          <div class="mb-3">
            <textarea class="form-control" :class="{'is-invalid' : errors.value && errors.value.bomComment}" v-model="store.getters.currentComposite.bomComment"></textarea>
          </div>
          <div class="text-end">
            <button
              type="submit"
              ref="submitButton"
              class="btn btn-sm btn-primary"
              @click="submitForm"
            >
              <span class="indicator-label">
                Save BOM Comment
              </span>

              <span class="indicator-progress">
                Please wait...
                <span
                  class="spinner-border spinner-border-sm align-middle ms-2"
                ></span>
              </span>
            </button>
          </div>
        </div>

      </template>

      <template v-slot:bomCost="slotProps">
        <div>{{ numeral(slotProps.row.bomCost).format('0,0.00000') }}</div>
      </template>

      <template v-slot:actions="slotProps">

        <div>

          <div class="btn-group" role="group">

            <EditBOM :bom="slotProps.row" @input="slotProps.search()"/>

            <DeleteRecord :id="slotProps.row.id" :action="actions.DELETE_PRODUCT_BOM" :callback="slotProps.search"/>

          </div>

        </div>

      </template>

    </Table>

  </div>
</template>
<script lang="ts">
import { Actions } from "@/store/enums/ProductBOMEnums";
import { Actions as ProductActions } from "@/store/enums/ProductEnums";
import Table from '@/components/custom/Table.vue';
import DeleteRecord from '@/components/custom/DeleteRecord.vue';
import AddBOM from "@/views/products/composites/edit/bom/AddBOM.vue";
import EditBOM from "@/views/products/composites/edit/bom/EditBOM.vue";
import { useStore } from 'vuex'
import { computed, ref, reactive, onMounted } from 'vue'
import numeral from 'numeral'
export default {
  name : 'CompositeBOM',
  props : ["id"],
  components : {
    Table,
    DeleteRecord,
    AddBOM,
    EditBOM
  },
  setup(props){

    const store = useStore();

    const success = ref(false)

    const errors = reactive({
      value : {}
    });

    onMounted(() => {
      store.dispatch(ProductActions.GET_COMPOSITE ,{id : props.id});
      store.dispatch(ProductActions.GET_ALL_COMPONENTS,{type : 'component'});
    });

    const actions = computed(() => {
      return Actions;
    })

    const submitButton = ref<HTMLElement | null>(null);



    const submitForm = () => {

      if(submitButton.value){
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      store.dispatch(Actions.ADD_BOM_COMMENT,{bomComment : store.getters.currentComposite.bomComment, id : props.id}).then(() => {

        success.value = true;
        errors.value = [];
        submitButton.value?.removeAttribute("data-kt-indicator");
        window.scrollTo(0,0);

      }).catch((response) => {
        setTimeout(function(){
          const input = document.getElementsByClassName('is-invalid')[0] as HTMLElement
          input.focus();
        },100)

        errors.value = response.data.errors;

        submitButton.value?.removeAttribute("data-kt-indicator");

      })
    }


    const columns = [
      {name : 'code', title : 'Product Code', sortable : true, sort : 'asc', searchable : true},
      {name : 'name', title : 'Product Name', sortable : true, sort : null, searchable : true},
      {name : 'quantity', title : 'Quantity', sortable : true, sort : null, searchable : true},
      {name : 'bomCost', title : 'Cost Price', sortable : true, sort : null, searchable : true},
      {name : 'actions', title : 'Edit / Delete',sortable : false, sort : null, searchable : false}
    ];

    return {
      actions,
      props,
      columns,
      store,
      submitForm,
      success,
      errors,
      numeral
    }
  }
}
</script>
