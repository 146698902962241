
import { onMounted } from 'vue';
import CompositeDetails from '@/views/products/composites/edit/CompositeDetails.vue'
import CompositeBOM from '@/views/products/composites/edit/CompositeBOM.vue'
import TransportCosts from '@/views/products/composites/edit/TransportCosts.vue'
import CompositeActivities from '@/views/products/composites/edit/CompositeActivities.vue'
import { useRoute } from 'vue-router';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
export default {
  name: 'CompositeEdit',
  components : {
    CompositeDetails,
    CompositeBOM,
    TransportCosts,
    CompositeActivities
  },
  setup(){
    const route = useRoute();

    onMounted(() => {
      setCurrentPageBreadcrumbs("Products",[
        {title : "Products",to : "/products/list"},
        {title : "Edit Composite",to : "/products/composite/edit/"+route.params.id}
      ]);
    });

    return {
      route
    }
  }
}
